




























































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import metaStore from "@/store/modules/meta";
import { Route }  from "@/store/models/route";
import commonstore from "@/store/modules/common";

@Component({
  components: {}
})
export default class RouteCreateView extends Vue {
  route: Route = {} as Route;

  async created() {
    const id:any = this.$route.query["id"];
    if (id) {
      await metaStore.getRouteById(id);
      this.route = metaStore.route;
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }

  async submit() {
    const id = this.$route.query["id"];
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    commonstore.showLoading();
    if(id) {
      await metaStore.patchRouteDD(this.route);
      this.$snotify.success("Route  Changed Successfully");
    }
    else{
      await metaStore.createRouteDD(this.route);
      this.$snotify.success("Route  Created Successfully");
    }
    commonstore.hideLoading();
  }

  async cancelForm() {
    this.$router.push(AdminRouter.RouteInfo);
  }
}
